.container.contact-container {
    width: 50%;
    display: grid;
    grid-template-columns: 43% 50%;
    gap: 7%;
}

.contact-options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
.contact-option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}
.contact-option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}
.contact-option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}
.contact-option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

/* ===========FORM========= */

form {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}
input,
textarea {
    width: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);
}
textarea {
    font-family: "Poppins", sans-serif;
}
.btn.btn-primary.form-btn {
    padding: 0.7rem 1.1rem;
    font-size: 1rem;
}

/*========== Media Queries ( LAPTOP  1024px)============ */

@media screen and (max-width: 1024px) {
    .container.contact-container {
        grid-template-columns: 1fr;
        gap: 1.5rem;
        width: var(--container-width-XL);
    }
    .contact-options {
        flex-direction: unset;
        justify-content: center;
        gap: 2rem;
    }
    .contact-option:nth-last-of-type(1),
    .contact-option:nth-last-of-type(3) {
        flex-basis: 27%;
    }
    .contact-option:nth-last-of-type(2) {
        transform: scale(1.1);
    }

    form {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
    }

    textarea {
        grid-row: 2;
        grid-column: 1/3;
    }
}

/* Media Queries (SMALL DEVICES) */

@media screen and (max-width: 768px) {
    .contact-option {
        padding: 1rem;
    }
    .contact-options {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
        gap: 1rem;
    }
    .contact-option:nth-last-of-type(2) {
        transform: scale(1);
    }
    form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}

@media screen and (max-width: 425px) {
    .container.contact-container {
        width: 13rem;
    }
    .contact-option {
        padding: 0.5rem;
    }
    form {
        align-items: center;
    }
    input,
    textarea {
        padding: 0.5rem;
    }
    .btn.btn-primary.form-btn {
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
    }
}
