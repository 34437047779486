.services-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.service {
    background: var(--color-bg-variant);
    border-radius: 0 0 2rem 2rem;
    border: 1px solid var(--color-primary);
    height: fit-content;
    transition: var(--transition);
}
.service:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}
.service-head {
    background: var(--color-primary);
    padding: 1.5rem;
    border-radius: 0 0 2rem 2rem;
    box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}
.service-head > h3 {
    color: var(--color-bg);
    font-size: 1rem;
    text-align: center;
}
.service-list {
    padding: 2rem;
}
.service-list li {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
}
.service-list-icon {
    color: var(--color-primary);
    margin-top: 2px;
}
.service-list p {
    font-size: 0.9rem;
}

/*======Media Queries (LARGE DEVICES LAPTOP  1200px) ======*/

@media screen and (max-width: 1200px) {
    .container.services-container {
        grid-template-columns: 1fr;
        width: 50%;
    }
    .service-head {
        padding: 0.5rem;
        border-radius: 0 0 3rem 3rem;
    }
    .service {
        height: auto;
        width: 100%;
    }
    .service-list li {
        justify-content: center;
    }
}

/*========== Media Queries ( LAPTOP  1024px)============ */

@media screen and (max-width: 1024px) {
    .container.services-container {
        width: 60%;
    }
}

@media screen and (max-width: 768px) {
    .container.services-container {
        width: var(--container-width-M);
        gap: 1.5rem;
    }
}
@media screen and (max-width: 425px) {
    .container.services-container {
        width: var(--container-width-S);
        gap: 1.5rem;
    }
    .service-list {
        padding: 1.5rem 0.5rem;
    }
    .service-list p {
        font-size: 0.8rem;
    }
}
