* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg: #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);
    --container-width-XL: 75%;
    --container-width-L: 80%;
    --container-width-M: 23rem;
    --container-width-S: 18rem;
    --transition: all 400ms ease;
}
html {
    scroll-behavior: smooth;
}
::-webkit-scrollbar {
    display: none;
}
body {
    font-family: "Poppins", sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    background-image: url(..//src/assets/bg-texture.png);
}

/* Genaral Styles */

.container {
    width: var(--container-width-XL);
    margin: auto;
}
h1,
h2,
h3,
h4,
h5 {
    font-size: 500;
}
h1 {
    font-size: 2.5rem;
}
section {
    padding-top: 8rem;
}
section > h2,
section > h5 {
    text-align: center;
    color: var(--color-light);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}
.text-light {
    color: var(--color-light);
}
a {
    color: var(--color-primary);
    transition: var(--transition);
}
a:hover {
    color: var(--color-white);
}
.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.3rem 1.5rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}
.btn:hover {
    background: var(--color-white);
    color: var(--color-bg);
}
.btn-primary {
    background: var(--color-primary);
    color: var(--color-bg);
}
img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/*======Media Queries (LARGE DEVICES LAPTOP  1200px) ======*/

/*========== Media Queries ( LAPTOP  1024px)============ */

@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-L);
    }
    section {
        padding-top: 6rem;
    }
}

/* =========Media Queries (TABLET  768px)=========== */

@media screen and (max-width: 768px) {
    .container {
        width: var(--container-width-M);
    }
    section {
        padding-top: 5rem;
    }
}

/* =========Media Queries (Mobile  425px)=========== */

@media screen and (max-width: 425px) {
    .container {
        width: var(--container-width-S);
    }
    section {
        padding-top: 3.5rem;
    }
}
