footer {
    background: var(--color-primary);
    padding: 1rem 0 3rem;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
}

footer a {
    color: var(--color-bg);
}

/* .footer-logo {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 1rem;
    display: inline-block;
} */
.permalinks {
    padding-top: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 1rem;
}
.footer-socials {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.footer-socials a {
    display: flex;
    background: var(--color-bg);
    color: var(--color-white);
    padding: 0.8rem;
    border-radius: 0.7rem;
    border: 1px solid transparent;
    transition: var(--transition);
}
.footer-socials a:hover {
    background: transparent;
    color: var(--color-bg);
    border-color: var(--color-bg);
}

.footer-copyright {
    margin-bottom: 4rem;
    color: var(--color-bg);
}

@media screen and (max-width: 768px) {
    .permalinks {
        gap: 0.5rem 1.5rem;
    }

    .footer-socials {
        margin-bottom: 1rem;
        font-size: 0.8rem;
    }
    .footer-copyright {
        margin-bottom: 0rem;
    }
    footer {
        padding: 1rem 0;
    }
}

@media screen and (max-width: 425px) {
    footer {
        margin-top: 4rem;
        padding-top: 0.5rem;
    }
    .permalinks {
        font-size: 0.8rem;
        padding: 0.5rem 1rem;
        gap: 0.3rem 1.5rem;
    }
    .footer-copyright {
        font-size: 0.7rem;
        padding-top: 0.5rem;
    }
    .footer-socials a {
        padding: 0.5rem;
    }
}
