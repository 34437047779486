.experience-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}
.experience-container > div {
    background: var(--color-bg-variant);
    padding: 2.4rem 4rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}
.experience-container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);

    cursor: default;
}
.experience-container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

.experience-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
}
.experience-details {
    display: flex;
    gap: 1rem;
}

.experience-details-icon {
    margin-top: 6px;
    color: var(--color-primary);
}

/*========== Media Queries ( LAPTOP  1024px)============ */

@media screen and (max-width: 1024px) {
    .experience-container > div {
        width: 100%;
        padding: 2rem;
        margin: 0 auto;
    }
    .experience-content {
        column-gap: 0.5rem;
    }
}
@media screen and (max-width: 768px) {
    .experience-container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
    .experience-container > div {
        padding: 2rem 1rem;
    }
    .experience-details {
        justify-content: center;
    }
}

@media screen and (max-width: 425px) {
    .experience-content {
        row-gap: 1rem;
    }
}
