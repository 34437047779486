header {
    padding-top: 4rem;
    overflow: hidden;
    height: max-content;
}
.header-container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* ===========CTA========= */

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ===========HEADER SOCIALS========= */

.header-socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 6rem;
}
.header-socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ==========image========= */

.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: relative;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 2rem 1.5rem 0 1.5rem;
}

/* ========Scroll Down======== */

.scroll-down {
    position: absolute;
    right: -2.3rem;
    bottom: 9rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* =========Media Queries (TABLET  768px)=========== */
@media screen and (max-width: 768px) {
    .header-socials,
    .scroll-down {
        display: none;
    }
    .header-container h1 {
        font-size: 2rem;
    }
    .me {
        width: 18rem;
        height: 27rem;
        left: calc(50% - 9rem);
    }
    header {
        padding-top: 6rem;
    }
}

@media screen and (max-width: 425px) {
    .me {
        width: 14rem;
        height: 20rem;
        left: calc(50% - 7rem);
        margin-top: 2rem;
    }
    header {
        padding-top: 4rem;
    }
    .cta {
        margin-top: 1.5rem;
        font-size: 0.8rem;
    }
    .header-container h1 {
        font-size: 1.65rem;
    }
}
