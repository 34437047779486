.portfolio-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.portfolio-item {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}
.portfolio-item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}
.portfolio-item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}
.portfolio-item h3 {
    height: 9rem;
    padding: 1rem 0;
}
.portfolio-item-cta {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

/*======Media Queries (LARGE DEVICES LAPTOP  1200px) ======*/

@media screen and (max-width: 1200px) {
    .portfolio-container {
        grid-template-columns: 1fr 1fr;
        gap: 3rem 2rem;
    }
}

@media screen and (max-width: 1024px) {
    .portfolio-container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
}

@media screen and (max-width: 768px) {
    .portfolio-container {
        gap: 1.5rem;
        grid-template-columns: 1fr;
    }
    .portfolio-item-image {
        height: 13rem;
    }
    .portfolio-item h3 {
        font-size: 1.1rem;
        height: fit-content;
        text-align: center;
    }
    .portfolio-item-cta {
        justify-content: center;
    }
}

@media screen and (max-width: 425px) {
    .portfolio-container {
        width: var(--container-width-S);
    }
    .portfolio-item {
        padding: 0.8rem;
    }
}
